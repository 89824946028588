import {useContext, useRef} from "react";
import {StateContext} from "../Contexts";
import {addToCart, getPlanVariantForPeriod} from "../Billwerk/SubscriptionJS";
import Radio from "@erecht24/frontend-kit/theme/components/inputs/Radio";
import {PERIOD_ANNUALLY, PERIOD_ANNUALLY_MONTHLY_PAID, SETUP_FEE, SETUP_FEE_DESCRIPTION} from "../Constants";
import formatCurrency from "../Util/formatCurrency";

export default function ({disabled}) {
    /** @type CheckoutState */
    const state = useContext(StateContext);
    const monthlyPayment = useRef(null);
    const annualPayment = useRef(null);

    const updateState = () => {
        let selectedPeriod = monthlyPayment.current.checked ? PERIOD_ANNUALLY_MONTHLY_PAID : PERIOD_ANNUALLY;
        state.setPeriod(selectedPeriod);

        state.setCart(cart => addToCart(state, cart, {
            PlanVariantId: getPlanVariantForPeriod(state, selectedPeriod)?.id
        }));
    };

    return <div className="grid grid-cols-1 gap-6 pt-4">
        <p className={(disabled ? 'text-graphite opacity-50' : 'text-blue-dark') + ` text-xl text-left font-medium leading-[1.375rem]`}>Zahlungsintervall für Ihren Jahrestarif wählen</p>
        <div className={(disabled ? 'opacity-50' : '') + ` grid grid-cols-1 gap-3 xl:grid-cols-2 xl:gap-12`}>
            <div>
                <Radio name="payment_variant"
                       inputRef={annualPayment}
                       onChange={updateState}
                       checked={state.period === PERIOD_ANNUALLY}
                       label={<>
                    Jährlich
                    <strong className="font-bold ml-2">
                        ({formatCurrency(getPlanVariantForPeriod(state, PERIOD_ANNUALLY)?.recurring_fee, true)} einmalig)
                    </strong>
                </>}></Radio>
                {state.mainProps.setup &&
                    <div><span className="inline-block pr-3 w-6">&nbsp;</span><span className="strikethrough-price-straight">{SETUP_FEE_DESCRIPTION}: {formatCurrency(SETUP_FEE, true)}</span></div>
                }
            </div>
            <div>
                <Radio name="payment_variant"
                       inputRef={monthlyPayment}
                       onChange={updateState}
                       checked={state.period === PERIOD_ANNUALLY_MONTHLY_PAID}
                       label={<>
                           Monatlich
                           <strong className="font-bold ml-2">
                               ({formatCurrency(getPlanVariantForPeriod(state, PERIOD_ANNUALLY_MONTHLY_PAID)?.recurring_fee, true)} jeweils)
                           </strong>
                </>}></Radio>
                {state.mainProps.setup &&
                    <div><span className="inline-block pr-3 w-6">+</span>{SETUP_FEE_DESCRIPTION}: {formatCurrency(SETUP_FEE, true)}</div>
                }
            </div>
        </div>
    </div>
}
