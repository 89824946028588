import Cart from "./Cart";
import Form from "./Form";
import Header from "./Header";
import {StateContext} from "../Contexts";
import {useEffect, useLayoutEffect, useState} from "react";
import {
    PERIOD_ANNUALLY,
    STEP_ADDRESS,
    STEP_PAYMENT,
    STEP_PLAN
} from "../Constants";
import CustomerData from "../Billwerk/CustomerData";
import PaymentData from "../Billwerk/PaymentData";
import PaymentOptionData from "../Util/PaymentOptionData";
import StepData from "../Util/StepData";
import ErrorFallback from "@erecht24/frontend-kit/theme/components/misc/ErrorFallback";
import * as Sentry from "@sentry/react";
import {
    getPlanVariantByExternalId,
    getPlanVariantById, getPlanVariantPeriod,
    initActiveProjectsCount,
    initCheckout,
    initSubscriptions,
    updateCart
} from "../Billwerk/SubscriptionJS";
import StoredState from "../Util/StoredState";
import GlobalAlert from "./GlobalAlert";
import {track} from "../Util/tracking";
import PrivacyPolicyDialog from "./PrivacyPolicyDialog";
import AgbDialog from "./AgbDialog";
import SiteNoticeDialog from "./SiteNoticeDialog";

/**
 * @typedef {Object} OptionalUrlParameters
 * @property {?string} product - Billwerk Plan Variant ID for preselected Plan Variant
 * @property {?string} variantLocked - Billwerk Plan Variant ID used within the checkout process, the user can not change the Plan Variant if set
 * @property {boolean} showVoucher - The coupon field is displayed or not
 * @property {boolean} showPaymentVariants - The radio buttons for annual or monthly payment are displayed for annual plan variants
 * @property {?string} code - A pre-specified coupon code
 * @property {boolean} hidePlans - Hide the pricing table (including the button to open the pricing table modal)
 * @property {boolean} hideQuantityComponents - Hide quantity based components
 * @property {boolean} hideOnOffComponents - Hide on off components
 * @property {?string} withComponents - Comma separated list of available components, which are displayed during checkout
 * @property {?string} erecht24UserUuid - eRecht24 User UUID if user is authenticated
 * @property {?string} checkoutVersion - force different layout version for A/B testing
 * @property {boolean} setup - include setup fee in checkout for A/B testing
 * @property {Object} mainDataSet - all set 'data-' attributes on <checkout-main>
 */

/**
 * @typedef {Object} CartViewPlan
 * @property {string} title
 * @property {number} monthlyPrice
 * @property {number} annualPrice
 * @property {number} feePeriodQuantity
 * @property {string} feePeriodUnit
 * @property {number} contractPeriodQuantity
 * @property {string} contractPeriodUnit
 */

/**
 * @typedef {Object} CartViewComponent
 * @property {string} title
 * @property {?string} quantity
 * @property {number} price
 * @property {boolean} includedInPlan
 */

/**
 * @typedef {Object} CartViewData
 * @property {CartViewPlan} plan
 * @property {CartViewComponent[]} components
 * @property {CartViewComponent[]} includedComponents
 * @property {string} couponCode
 * @property {boolean} couponAppliesToCart
 * @property {number} totalNetBeforeDiscount
 * @property {number} discountNet
 * @property {number} totalNet
 * @property {number} totalVat
 * @property {number} totalGross
 * @property {number} vatPercentage
 * @property {string} cartType
 * @property {number} nextTotalGross
 * @property {string} nextTotalGrossDate
 * @property {?string} changeDate
 */

/**
 * @typedef {Object} CartConfiguration
 * @property {string} PlanVariantId
 * @property {string} couponCode
 * @property {Object} components
 */

/**
 * @typedef {Object} CartUpdate
 * @property {string} PlanVariantId
 * @property {string} couponCode
 * @property {App_Http_Resources_Component} component
 * @property {number} componentQuantity
 * @property {Object} components
 */

/**
 * @typedef {Object} CheckoutState
 * @property {string} product - Current step in checkout process
 * @property {string} step - Current step in checkout process
 * @property {function} setStep
 * @property {string} goto - Anchor to scroll to after next rendering
 * @property {function} setGoto
 * @property {boolean} planDetailsOpen - Show plan details modal
 * @property {function} setPlanDetailsOpen
 * @property {boolean} planDialogOpen - Show pricing table modal
 * @property {function} setPlanDialogOpen
 * @property {string} period - Selected period
 * @property {function} setPeriod
 * @property {Object} customerData - Billwerk CustomerData
 * @property {function} setCustomerData
 * @property {string} customerCountry - Customer Country Code
 * @property {function} setCustomerCountry
 * @property {string} coupon - Current coupon code
 * @property {function} setCoupon
 * @property {boolean} addressFormEnabled
 * @property {function} setAddressFormEnabled
 * @property {string} countries - Available countries in countries dropdown form field
 * @property {function} setCountries
 * @property {Array} availablePaymentMethods - Array of available Billwerk payment methods
 * @property {function} setAvailablePaymentMethods
 * @property {string} paymentOption - Selected payment option
 * @property {function} setPaymentOption
 * @property {Object} paymentData - Additional payment data (if required, e.g. Bank Account)
 * @property {function} setPaymentData
 * @property {Object} cart - Billwerk cart to be used for cart request and checkout
 * @property {function} setCart
 * @property {CartViewData} cartViewData - Cart request result
 * @property {function} setCartViewData
 * @property {App_Http_Resources_ProductInfo} productInfo - Billwerk productInfo request result
 * @property {function} setProductInfo
 * @property {Object} contractData - Billwerk contract and customer data
 * @property {function} setContractData
 * @property {Number} activeProjectsCount
 * @property {function} setActiveProjectsCount
 * @property {boolean} subscriptionsInitialized
 * @property {function} setSubscriptionsInitialized
 * @property {Object} customerDataValidation - Validation result for address form
 * @property {function} setCustomerDataValidation
 * @property {Object} paymentDataValidation - Validation result for payment data (e.g. Bank Account validation)
 * @property {function} setPaymentDataValidation
 * @property {boolean} agbOpen - show AgbDialog
 * @property {function} setAgbOpen
 * @property {boolean} privacyPolicyOpen - show PrivacyPolicyDialog
 * @property {function} setPrivacyPolicyOpen
 * @property {boolean} siteNoticeOpen - show SiteNoticeDialog
 * @property {function} setSiteNoticeOpen
 * @property {string} globalErrorMessage - Show a global error message as alert
 * @property {function} setGlobalErrorMessage
 * @property {string} returnUrl - Where to return after payment
 * @property {OptionalUrlParameters} mainProps - URL parameters passed as props to main component
 */

export default function (props) {
    const [step, setStep] = useState(StepData);
    const [goto, setGoto] = useState(null);
    const [planDetailsOpen, setPlanDetailsOpen] = useState(false);
    const [planDialogOpen, setPlanDialogOpen] = useState(false);
    const [period, setPeriod] = useState(() => StoredState('checkout_payment_period', PERIOD_ANNUALLY));
    const [customerData, setCustomerData] = useState(CustomerData);
    const [customerCountry, setCustomerCountry] = useState(null);
    const [coupon, setCoupon] = useState(() => StoredState('checkout_coupon', props.voucher ?? null));
    const [addressFormEnabled, setAddressFormEnabled] = useState(false);
    const [countries, setCountries] = useState(null);
    const [availablePaymentMethods, setAvailablePaymentMethods] = useState(null);
    const [paymentOption, setPaymentOption] = useState(PaymentOptionData);
    const [paymentData, setPaymentData] = useState(PaymentData);
    const [cart, setCart] = useState(() => StoredState('checkout_cart', null));
    const [cartViewData, setCartViewData] = useState(() => StoredState('checkout_cart_view_data', null));
    const [productInfo, setProductInfo] = useState(null);
    const [contractData, setContractData] = useState(null);
    const [activeProjectsCount, setActiveProjectsCount] = useState(null);
    const [subscriptionsInitialized, setSubscriptionsInitialized] = useState(false);
    const [customerDataValidation, setCustomerDataValidation] = useState([]);
    const [paymentDataValidation, setPaymentDataValidation] = useState([]);
    const [agbOpen, setAgbOpen] = useState(false);
    const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
    const [siteNoticeOpen, setSiteNoticeOpen] = useState(false);
    const [globalErrorMessage, setGlobalErrorMessage] = useState(() => sessionStorage?.getItem('global_error_message') ?? null);

    const updateStep = (step, updateHistory = true, goto = null) => {
        if (state.step === step) {
            return;
        }

        if (updateHistory) {
            window.history.pushState({step: step}, null, '#' + step);
        }

        sessionStorage?.setItem('checkout_current_step', step);
        window.scrollTo(0, 0);
        setStep(step);
        setGoto(goto);
    };

    const onPopStateListener = (event) => {
        if (event.state?.step) {
            updateStep(event.state.step, false);
        } else {
            updateStep(STEP_PLAN, false);
        }
    };

    useEffect(() => {
        window.addEventListener('popstate', onPopStateListener);
        return () => {
            window.removeEventListener('popstate', onPopStateListener);
        };
    });

    /** @type CheckoutState */
    const state = {
        step,
        setStep: updateStep,

        goto, setGoto,
        planDetailsOpen, setPlanDetailsOpen,
        planDialogOpen, setPlanDialogOpen,
        period, setPeriod,
        customerData, setCustomerData,
        customerCountry, setCustomerCountry,
        coupon, setCoupon,
        addressFormEnabled, setAddressFormEnabled,
        countries, setCountries,
        availablePaymentMethods, setAvailablePaymentMethods,
        paymentOption, setPaymentOption,
        paymentData, setPaymentData,
        cart, setCart,
        cartViewData, setCartViewData,
        productInfo, setProductInfo,
        contractData, setContractData,
        activeProjectsCount, setActiveProjectsCount,
        subscriptionsInitialized, setSubscriptionsInitialized,
        customerDataValidation, setCustomerDataValidation,
        paymentDataValidation, setPaymentDataValidation,
        agbOpen, setAgbOpen,
        privacyPolicyOpen, setPrivacyPolicyOpen,
        siteNoticeOpen, setSiteNoticeOpen,
        globalErrorMessage, setGlobalErrorMessage,

        // payment return url
        returnUrl: props.returnUrl,

        mainProps: props
    };

    useLayoutEffect(() => {
        if (state.productInfo === null || state.countries === null) {
            return;
        }

        let planVariant = getPlanVariantById(state, state.cart?.PlanVariantId);

        if (!planVariant && !state.mainProps.variantLocked && !state.mainProps.product) {
            state.setPlanDialogOpen(true);
            return;
        }

        let period = getPlanVariantPeriod(planVariant, false);

        if (period && state.period !== period) {
            state.setPeriod(period);
            return; // return here, useLayoutEffect will get executed again when period was updated
        }

        if (state.mainProps.erecht24UserUuid) {
            if (state.cartViewData && state.cartViewData.cartType !== 'initial_purchase' && state.activeProjectsCount === null) {
                initActiveProjectsCount(state);
            }

            if (!state.subscriptionsInitialized) {
                initSubscriptions(state);
            }
        }

        updateCart(state);
    }, [productInfo, customerCountry, cart, period, activeProjectsCount, subscriptionsInitialized]);

    useEffect(() => {
        sessionStorage?.setItem('checkout_customer_data', JSON.stringify(state.customerData));
    }, [customerData]);

    useEffect(() => {
        sessionStorage?.setItem('checkout_payment_option', JSON.stringify(state.paymentOption));
    }, [paymentOption]);

    useEffect(() => {
        sessionStorage?.setItem('checkout_payment_data', JSON.stringify(state.paymentData));
    }, [paymentData]);

    if (state.productInfo === null || state.countries === null) {
        track(
            () => initCheckout(state),
            'checkout',
            'navigation',
            'checkout_menu',
            step === STEP_PLAN ? 'tariff' : ((step === STEP_ADDRESS || step === STEP_PAYMENT) ? 'address' : null)
        );
    }

    let stepClass = 'step--' + state.step;

    return <Sentry.ErrorBoundary fallback={errorProps => <>
        <div className="max-w-[1320px] lg:ml-[2.5rem] lg:pr-[2.5rem] 2xl:mx-auto 2xl:pr-0 pt-10">
            <ErrorFallback {...errorProps} resetErrorBoundary={() => {
                track(null, 'checkout', 'payment_status', 'payment_failed', 'try_again');
                window.sessionStorage?.clear();
                window.location.reload();
            }} />
        </div>
    </>}>
        <StateContext.Provider value={state}>
            <>
                <div className={`checkout--main ${stepClass} checkout--main--version-${props.checkoutVersion ?? 'default'}`}>
                    <GlobalAlert errorMessage={state.globalErrorMessage} onClose={() => {
                        state?.setGlobalErrorMessage(null);
                        sessionStorage?.removeItem('global_error_message');
                    }}/>
                    <div className="checkout--header">
                        <Header/>
                    </div>
                    <div className="checkout--form">
                        <Form/>
                    </div>
                    <div className="checkout--cart">
                        <Cart/>
                    </div>
                </div>
                {!props.hideFooter &&
                    <footer
                        className="flex flex-col items-center lg:flex-row lg:justify-between gap-5 max-w-[1320px] lg:ml-[2.5rem] lg:pr-[2.5rem] 2xl:mx-auto 2xl:pr-0 pt-10 pb-[277px] lg:pb-10 border-t border-t-gray-light">
                        <div className="text-lg text-graphite">
                            Copyright &copy; eRecht24
                        </div>
                        <ul className="flex flex-row flex-wrap justify-center lg:justify-end gap-x-6 lg:gap-x-10">
                            <li>
                                <a className="text-gray-dark hover:text-gray-very-dark hover:underline"
                                   href="#erecht24-chat-revoke-consent"></a>
                            </li>
                            <li>
                                <a className="text-gray-dark hover:text-gray-very-dark hover:underline hover:cursor-pointer"
                                   onClick={() => track(
                                       () => state.setPrivacyPolicyOpen(true),
                                       'checkout',
                                       'footer_privacy_policy',
                                       'footer_privacy_policy'
                                   )}>Datenschutz</a>
                            </li>
                            <li>
                                <a className="text-gray-dark hover:text-gray-very-dark hover:underline hover:cursor-pointer"
                                   onClick={() => track(
                                       () => state.setAgbOpen(true),
                                       'checkout',
                                       'footer_agb',
                                       'footer_agb'
                                   )}>AGB</a>
                            </li>
                            <li>
                                <a className="text-gray-dark hover:text-gray-very-dark hover:underline hover:cursor-pointer"
                                   onClick={() => track(
                                       () => state.setSiteNoticeOpen(true),
                                       'checkout',
                                       'footer_site_notice',
                                       'footer_site_notice'
                                   )}>Impressum</a>
                            </li>
                        </ul>
                    </footer>
                }
                <AgbDialog open={state.agbOpen} handleOpen={() => state.setAgbOpen(!state.agbOpen)}/>
                <PrivacyPolicyDialog open={state.privacyPolicyOpen} handleOpen={() => state.setPrivacyPolicyOpen(!state.privacyPolicyOpen)}/>
                <SiteNoticeDialog open={state.siteNoticeOpen} handleOpen={() => state.setSiteNoticeOpen(!state.siteNoticeOpen)}/>
            </>
        </StateContext.Provider>
    </Sentry.ErrorBoundary>
}
